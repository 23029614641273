.relative {
    position: relative;
  }
  
  .speech-bubble {
    position: relative;
    background: #f9f9f9;
    border-radius: 15px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .speech-bubble::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 20px;
    border-width: 10px;
    border-style: solid
  }